/* @media screen and (min-width:1440px) {
    .container {
        max-width: 1140px
    }
} */



@media screen and (min-width:1024px) and (max-width: 1200px) {
    .main-owl .owl-carousel .owl-nav.disabled {
        left: -251px !important;
    }

    .trainer .card {
        width: auto !important;
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute !important
    }

    .benefit1 .card {
        height: auto !important;
    }

    .g-img4 {
        bottom: 20px !important;
        left: 0px !important;
        width: 40% !important;
    }

    .g-img1 {
        left: 9px !important;
        top: 146px !important;
        width: 33% !important;
    }

    .fa-circle-play {
        top: 33%;
        left: 47%;
    }

    .navbar-light .navbar-nav .nav-link {
        text-align: center;
    }

    .magnet .card {
        height: 340px !important;
    }

    .magnet .g-1 {
        --bs-gutter-x: 2rem !important;
    }

    .magnet .logo8 {
        top: 31% !important;
        left: 8% !important;
    }

    .magnet .logo1 {
        margin-top: -175px !important;
        right: 1% !important;
        width: 25% !important;
    }

    .support .logo30 {
        left: 87px !important;
        width: 79% !important;
        top: 51% !important;
    }

    .benefit .logo1 {
        right: -9% !important;
        top: 43% !important;
        width: 91% !important;
    }

    .lab1 .logo1 {
        right: -7% !important;
        top: 47% !important;
    }

    .science .logo1 {
        right: -7% !important;
        width: 77% !important;
    }

    .benefit1 .logo1 {
        top: 30% !important;
    }

    .benefit1 .logo1 {
        right: -9% !important;
    }

    .subject .logo1 {
        right: -7% !important;
        width: 79% !important;
        top: 51% !important;
    }

    .benefit2 .logo1 {
        width: 93% !important;
        top: 36% !important;
        right: -8% !important;
    }
}

@media screen and (min-width:768px) and (max-width: 1024px) {
    .Girl {
        height: 400px !important;
        width: auto !important;
    }

    .technology {
        height: 100% !important;
        border-bottom-left-radius: 50% 9% !important;
        border-bottom-right-radius: 50% 9% !important;
    }

    .technology .h1 {
        font-size: 26px !important;
        margin-bottom: 10px !important;
    }


    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: relative !important;
        border-radius: 10px !important;
        padding: 11px 25px !important;
        margin-bottom: 13px !important;
    }

    .trade .card {
        width: 32% !important;
    }

    .trade-con .logo1 {
        bottom: 114px !important;
        right: -22px !important;
    }

    .trade .logo {
        top: 123px !important;
        left: -31px !important;
    }

    .trade .card-text {
        font-size: 14px !important;
    }

    .trade .card-img-top {
        padding: 22px !important;
    }

    .trade .btn {
        margin-top: 20px !important;
    }

    .two .row>div:first-child {
        padding: 0px 0px !important;
    }

    .two .logo2,
    .banner .img2 {
        width: 100% !important;
        height: auto !important;
    }

    .two .para2 {
        font-size: 14px !important;
    }

    .navbar .nav-item {
        margin: 0 7px !important;
    }

    .video2 img {
        width: 53vw !important;
    }

    .choose .logo9 {
        bottom: -3rem !important;
        right: -1rem !important;
    }

    .choose .logo8 {
        top: 6rem !important;
        left: -1rem !important;
    }

    .choose .para3 {
        width: auto !important;
        font-size: 13px !important;
    }

    .choose .h3 {
        line-height: 36px !important;
        font-size: 16px !important;
    }

    .choose .row {
        padding: 10px !important;
    }

    .contact .overlay {
        margin-top: 20px !important;
        margin-left: 35px !important;
    }

    .contact .text {
        width: 267px !important;
        margin-bottom: 12px !important;
    }

    .contact .title {
        font-size: 25px !important;
        width: 54% !important;
        line-height: normal !important;
    }

    .contact .text {
        width: 45% !important;
        margin-bottom: 10px !important;
        margin-top: 10px !important;
    }

    .owl-theme .owl-nav {
        margin-top: 10px;
        text-align: left !important;
        position: relative !important;
        left: -183px !important;
        top: -184px !important;
    }

    .trainer .h6 {
        width: 25vw !important;
    }

    .plat,
    .tech,
    .develop,
    .corporation,
    .training {
        height: 34vh !important;
    }

    .university {
        height: 29vh !important;
    }

    .plat .back,
    .tech .back,
    .develop .back,
    .university .back,
    .training .back {
        padding-top: 15px !important;
        margin-left: 0 !important;
    }

    .plat .h1,
    .tech .h1,
    .develop .h1,
    .corporation .h1,
    .university .h1,
    .training .h1 {
        font-size: 24px !important;
        line-height: normal !important;
    }

    .corporation .h1 {
        margin-top: 20px !important;
    }

    .plat .para1,
    .tech .para1,
    .develop .para1,
    .corporation .para1,
    .university .para1,
    .training .para1 {
        font-size: 14px !important;
        line-height: normal !important;
    }

    .list .col-2 {
        width: 18.66666667% !important;
    }

    .banner .title,
    .cloud .title,
    .innovation .title {
        font-size: 25px !important;
        margin-bottom: 0px !important;
    }

    .banner .para10,
    .innovation .para21 {
        font-size: 12px !important;
        line-height: normal !important;
    }

    .banner .btn {
        font-size: 12px !important;
        width: auto !important;
        height: auto !important;
    }

    .features .row:last-child {
        padding: 0px !important;
    }

    .cloud .title {
        margin: 0px !important;
    }

    .target .card {
        width: 46% !important;
        height: auto !important;
        margin: 10px 12px !important;
    }

    .main-owl .owl-carousel .owl-nav.disabled {
        width: 25% !important;
    }

    .growth .title {
        font-size: 32px !important;
        margin-bottom: 30px !important;
    }

    .magnet .card {
        width: auto !important;
        height: 380px !important;
        padding: 10px !important;
    }

    .magnet .title1 {
        font-size: 21px !important;
        margin-bottom: 10px !important;
    }

    .magnet .para18 {
        font-size: 12px !important;
    }

    .support .logo30 {
        left: 128px !important;
    }

    .support .logo {
        top: -46px !important;
    }

    .tar .card-one,
    .tar .card {
        width: 50% !important;
        margin: 0px !important;
    }

    .tar .para26 {
        font-size: 14px !important;
    }

    .col-lapse {
        margin: 0px !important;
    }

    .digital .img21,
    .design .img21,
    .drive .img21,
    .touch .img30 {
        width: 100% !important;
        height: fit-content !important;
    }

    .lab1 .card {
        width: auto !important;
        height: 315px !important;
        padding: 20px !important;
    }


    .subject .logo1 {
        right: -10% !important;
        width: 90% !important;
        top: 57% !important;
    }

    .benefit2 .logo1 {
        right: -23px !important;
    }

    .lab1 .logo1 {
        right: -10% !important;
    }

    .lab1 .logo8 {
        left: -28px !important;
    }

    .image .img29,
    .image3 .img29,
    .image2 .img29,
    .image6 .img29,
    .image5 .img29,
    .image9 .img29 {
        width: 100% !important;
    }

    .benefit .card,
    .science .card,
    .benefit1 .card,
    .subject .card,
    .benefit2 .card {
        width: auto !important;
        padding: 20px !important;
    }

    .benefit2 .logo1 {
        width: 93% !important;
        top: 50% !important;
        right: -13% !important;
    }

    .benefit .logo8 {
        left: -36px !important;
        width: 100% !important;
        top: -19% !important;
    }

    .science .logo1 {
        right: -9% !important;
        width: 100% !important;
    }

    .benefit1 .para36 {
        margin-top: 20px !important;
    }

    .benefit2 .logo8 {
        top: -17% !important;
        left: -10% !important;
        width: 88% !important;
    }

    .benefit1 .logo8 {
        left: -24px !important;
        width: 100% !important;
        top: -18% !important;
    }

    .benefit2 .card {
        height: 226px !important;
    }

    .contact-k {
        margin-top: 30px;
    }

    .nav2 {
        height: auto !important;
        border-bottom-left-radius: 0% !important;
        border-bottom-right-radius: 0% !important;
    }

    .sub-card {
        padding: 4px 20px !important;
    }

    .price-banner {
        width: 100% !important;
    }

    .price-list {
        font-size: 24px !important;
    }

    /* .Girl2 {
        height: 30vh !important;
    } */

    .tech .Girl2,
    .develop .Girl2,
    .corporation .Girl2,
    .training .Girl2 {
        height: 34vh !important;
    }

    .g-img1 {
        left: -22px !important;
        top: 136px !important;
        width: 40% !important;
    }

    .g-img4 {
        bottom: 67px !important;
        left: -24px !important;
        width: 43% !important;
    }

    .g-img3 {
        right: -15px !important;
        top: 280px !important;
        width: 42% !important;
    }

    .p-img6 {
        left: 16% !important;
        top: 0 !important;
        width: 14% !important;
    }

    .p-img1 {
        right: 6% !important;
        top: 10% !important;
        width: 20% !important;
    }

    .p-img2 {
        right: 0px !important;
        bottom: 38% !important;
        width: 16% !important;
    }

    .p-img3 {
        left: 0 !important;
        top: 30% !important;
        width: 16% !important;
    }

    .p-img4 {
        right: 0px !important;
        bottom: 8% !important;
        width: 20% !important;
    }

    .p-img5 {
        bottom: 4% !important;
        left: -25px !important;
        width: 23% !important;
    }

    .l-img1 {
        right: 14% !important;
        top: 23% !important;
        width: 14% !important;
    }

    .l-img2 {
        right: -6% !important;
        bottom: 36% !important;
        width: 14% !important;
    }

    .l-img3 {
        left: 7% !important;
        top: 83% !important;
        width: 13% !important;
    }

    .l-img4 {
        right: -7% !important;
        top: 76% !important;
        width: 19% !important;
    }

    .l-img5 {
        bottom: 31% !important;
        left: 3% !important;
        width: 13% !important;
    }

    .l-img6 {
        left: 14% !important;
        top: 22% !important;
        width: 19% !important;
    }

    .develop .h1 {
        margin-bottom: 0px !important;
        margin-top: 0px !important;
    }

    .t-img1 {
        left: 7% !important;
        top: 20% !important;
        width: 20% !important;
    }

    .t-img2 {
        right: -2% !important;
        bottom: 12% !important;
        width: 20% !important;
    }

    .t-img3 {
        left: 1% !important;
        top: 59% !important;
        width: 20% !important;
    }

    .t-img4 {
        right: 7% !important;
        top: 20% !important;
        width: 20% !important;
    }

    .c-img1 {
        left: 24% !important;
        top: 6% !important;
        width: 18% !important;
    }

    .c-img2 {
        right: -7% !important;
        bottom: 54% !important;
        width: 20% !important;
    }

    .c-img3 {
        left: -15% !important;
        top: 79% !important;
        width: 20% !important;
    }

    .university .h1 {
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    .u-img1 {
        left: 41% !important;
        top: 18% !important;
        width: 20% !important;
    }

    .u-img2 {
        right: -11% !important;
        bottom: 54% !important;
        width: 20% !important;
    }

    .u-img3 {
        left: -19% !important;
        bottom: 0px !important;
        width: 20% !important;
    }

    .k-img1 {
        left: 4px !important;
        top: 2% !important;
    }

    .k-img2 {
        right: 2% !important;
        bottom: 73% !important;
        width: 20% !important;
    }

    .k-img3 {
        left: -18% !important;
        bottom: 0px !important;
    }

    .fa-circle-play {
        top: 31% !important;
        left: 44% !important;
    }

    .target,
    .magnet {
        position: relative;
    }

    .target .logo {
        left: 0% !important;
    }

    .target .logo1 {
        right: 0% !important;
    }

    .magnet .logo8 {
        top: 18% !important;
        left: 2% !important;
    }

    .innovation {
        margin-bottom: 75px !important;
    }

    .support .btn-new {
        display: flex;
        justify-content: center;
    }

    .benefit .logo1 {
        right: -22% !important;
        top: 58% !important;
        width: 100% !important;
    }

    .science .logo8 {
        top: -45px !important;
        left: -26px !important;
    }

    .benefit1 .logo1 {
        right: -12% !important;
        top: 55% !important;
        width: 100% !important;
    }

    .subject .logo8 {
        top: -14% !important;
        left: -10% !important;
        width: 80% !important;
    }
}

@media screen and (max-width:600px) {

    .container {
        width: 93%;
    }

    .Girl {
        width: 100% !important;
        height: auto !important;
    }

    .technology {
        border-bottom-left-radius: 50% 4% !important;
        border-bottom-right-radius: 50% 4% !important;
    }

    .template .para4 {
        width: 100%;
    }

    .owl-carousel.owl-drag .owl-item {
        display: flex;
        justify-content: center;
    }

    .trade .card,
    .trade .btn {
        width: auto !important;
        margin-top: 30px !important;
    }

    .trade .logo1,
    .trade .logo {
        display: none;
    }

    .logo2 {
        width: 100%;
        height: auto;
    }

    .two .h5 {
        margin-top: 20px;
    }

    .pop {
        position: absolute;
        left: 50px;
        top: 20px;
    }

    .video2>img {
        width: 78vw !important;
    }

    .lap-head {
        margin: 10px;
    }

    .video2 {
        top: -68px;
    }

    .about .logo5 {
        height: auto;
    }

    .choose .row {
        padding: 10px !important;
    }

    .choose .box {
        box-shadow: none !important;
    }

    .navbar-expand-lg .navbar-nav {
        display: block;
        flex: none;
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: relative !important;
        border-radius: 10px !important;
        padding: 11px 25px !important;
        margin-bottom: 13px !important;
    }

    .technology .h1 {
        margin-top: 30px;
    }

    .template .para4 {
        width: 100%;
    }

    .trade .card-img-top {
        padding: 15px;
    }

    .two .h4 {
        margin-top: 35px;
    }

    .choose .para3 {
        width: auto !important;
    }

    .trainer {
        margin-top: 10px;
    }

    .trainer .card {
        width: auto !important;
        height: 22rem !important;
    }

    .trainer .logo11 {
        margin-top: 8px !important;
    }

    .btn {
        width: fit-content !important;
    }

    .img2 {
        width: 100%;
    }

    .subtitle,
    .title {
        text-align: center !important;
    }

    .features .row {
        margin-top: 0px;
        padding-top: 0px;
    }

    .target .card {
        margin-right: 0px !important;
        margin-bottom: 20px;
        width: 100% !important;
    }

    .target .title {
        margin-bottom: 0px;
    }

    .target .row {
        margin: 0px;
    }

    .logo30 {
        display: none;
    }

    .btn-a1 {
        border: 1px solid #3D7851 !important;
        margin: 20px 0px;
    }

    .sml-btn {
        display: flex;
        justify-content: center;
        text-decoration: none;
    }

    .magnet .title {
        line-height: 50px;
    }

    .magnet .card {
        width: auto !important;
        height: auto !important;
        padding: 16px !important;
        box-shadow: 20px 20px 20px 0px rgba(61, 120, 81, 0.1), inset 4px 4px 10px white !important;
        border-radius: 26px !important;
        z-index: 1 !important;
        margin-bottom: 20px !important;
    }

    .gun .title1,
    .gun .para19 {
        width: auto !important;
        height: auto !important;
    }

    .support .logo {
        display: none;
    }

    .tar .card {
        height: auto !important;
    }

    .support .card,
    .message .para42 {
        width: auto !important;
    }

    .img21 {
        margin: 20px 0px;
        width: 100%;
    }

    .lab1 .logo8,
    .lab1 .logo1,
    .benefit .logo8,
    .benefit .logo1,
    .science .logo1,
    .science .logo8,
    .benefit1 .logo8,
    .benefit1 .logo1,
    .subject .logo8,
    .subject .logo1,
    .benefit2 .logo8,
    .benefit2 .logo1,
    .choose .logo8,
    .choose .logo9,
    .magnet .logo8,
    .magnet .logo1,
    .team .logo,
    .team .logo31,
    .target .logo1,
    .target .logo {
        display: none;
    }

    .box .col-lg-4 {
        margin-bottom: 20px;
    }

    .img29,
    .img30 {
        width: 100%;
    }

    .image,
    .image2,
    .image5 {
        margin: 10px 0px !important;
        padding: 0px !important;
    }

    .benefit .card {
        width: 100% !important;
        height: auto !important;
        margin: 10px 0px;
    }

    .col {
        font-size: medium !important;
    }

    .partner .container,
    .founderimg {
        width: 100% !important;
    }

    .owl-theme .owl-nav {
        display: none;
    }

    .science .title,
    .trainer,
    .subject .title,
    .subject {
        margin: 0px !important;
    }

    .science {
        margin-bottom: 10px !important;
    }

    .benefit1 .col4,
    .benefit1 .col,
    .benefit1 .col7,
    .benefit2 .col4,
    .benefit2 .col,
    .benefit2 .col7 {
        margin: 10px 0px;
        display: flex;
        justify-content: center;
    }

    .trainer .container {
        margin: 0px !important;
    }

    .contact-k {
        margin-top: 30px;
    }

    .social-sml,
    .global .trip {
        width: 100% !important;
    }

    .nav2 {
        height: auto !important;
        border-bottom-left-radius: 0% !important;
        border-bottom-right-radius: 0% !important;
    }

    .h-top2 {
        margin-top: 110px !important;
    }

    .dummyimage {
        width: 50px;
    }

    .previous {
        cursor: pointer;
        flex-direction: column;
        gap: 14px;
        align-items: start !important;
    }

    .next {
        cursor: pointer;
        flex-direction: column-reverse;
        gap: 14px;
        align-items: end !important;
    }

    .nextpost {
        padding: 1px !important;
    }

    .sim-post {
        margin: 20px 0px !important;
    }

    .two .row>div:first-child {
        padding: 0px 25px;
    }

    .list .col-lg-2 {
        width: 50% !important;
    }

    .growth .title {
        font-size: 30px !important;
        margin-bottom: 10px !important;
    }

    .video-abt {
        position: absolute;
        width: 66.5% !important;
        height: 72%;
        background-color: white;
        top: 10% !important;
        left: 17% !important;
    }

    .team .card {
        width: auto !important;
        height: auto !important;
        padding: 10px !important;
        border-radius: 26px;
        box-shadow: 20px 20px 20px 0px rgba(61, 120, 81, 0.1), inset 4px 4px 10px white;
        margin: 15px 0px !important;
    }

    .global .img36 {
        width: 100% !important;
        height: auto !important;
        margin: 0px !important;
    }

    .two .row>div:first-child {
        padding: 0px 12px !important;
    }

    .choose .box {
        margin-top: 30px !important;
    }

    .btn-subr,
    .price-banner {
        width: 100% !important;
    }

    .price-amt {
        font-size: 54px !important;
    }

    .sub-card {
        padding: 12px 20px !important;
    }

    .btn-price {
        font-size: 15px !important;
        height: 60px !important;
        margin-right: 0px !important;
    }

    .btn-price-2 {
        height: 60px !important;
        font-size: 15px !important;
        margin-right: 10px !important;
    }

    .sub-card .lic {
        top: 20px !important;
    }

    .price-sub {
        margin-top: 60px !important;
    }

    .btn-subr {
        margin-top: 0px !important;
    }

    .price-list {
        font-size: 16px !important;
    }

    .batch-card {
        width: 100% !important;
    }

    .batch-card button {
        font-size: 20px !important;
    }

    .g-img1 {
        left: 0% !important;
        top: 26% !important;
        width: 38% !important;
    }

    .g-img4 {
        bottom: 12% !important;
        left: 61% !important;
        width: 39% !important;
    }

    .g-img2 {
        right: 1% !important;
        top: 0% !important;
    }

    .g-img3 {
        right: 58% !important;
        top: 68% !important;
        width: 42% !important;
    }

    .fa-circle-play {
        top: -9% !important;
        left: 45% !important;
        font-size: 35px !important;
    }

    .pricingpagebanner {
        width: 100% !important;
    }

    .plat .back,
    .tech .back,
    .develop .back,
    .university .back,
    .training .back {
        padding-top: 20px !important;
    }

    .pricingdescription>p {
        width: 100% !important;
    }

    .plat .h1,
    .tech .h1,
    .develop .h1,
    .university .h1,
    .training .h1 {
        font-size: x-large !important;
        line-height: normal !important;
    }

    .develop .h1,
    .university .h1 {
        margin-bottom: 0 !important;
        margin-top: 0 !important;
    }

    .mount-white {
        bottom: -90px !important;
        border-top-left-radius: 50% 20% !important;
        border-top-right-radius: 48% 20% !important;
    }

    .training .Girl2 {
        height: 253px !important;
    }

    .p-img1 {
        right: 5% !important;
        top: 0% !important;
        width: 20% !important;
    }

    .p-img2 {
        right: 0% !important;
        bottom: 6% !important;
        width: 20%;
    }

    .p-img3 {
        left: -6px !important;
        top: 75% !important;
        width: 20%;
    }

    .pricing-form input {
        width: 4.4rem !important;
    }

    .pricing-form label {
        font-size: 13px !important;
        height: 3rem !important;
        display: flex !important;
        align-items: center !important;
        text-align: center !important;
    }
.apply-padding{
    padding: 0rem 1rem !important;
}
    .contact-price-content {
        padding: 25px 0px !important;
    }

    .pricing-form input{
        font-size: 13px !important; 
        padding: 4px !important;
    }

    .pricing-form div {
        margin-right: 0px !important;
        align-items: center !important;
    }

    .cohert-btn {
        margin-bottom: 2rem !important;
    }

    .wcardsec-2 {
        margin-right: 0 !important;
        padding: 23px 11px !important;
    }

    .pricing-user {
        flex-direction: column !important;
        align-items: center !important;
    }

    .p-img4 {
        right: 0% !important;
        top: 36% !important;
        width: 20% !important;
    }

    .p-img5 {
        bottom: 41% !important;
        left: -5% !important;
        width: 24% !important;
    }

    .p-img6 {
        left: 5% !important;
        top: 0% !important;
        width: 20% !important;
    }

    .l-img1 {
        right: 11% !important;
        top: 18% !important;
        width: 15% !important;
    }

    .l-img2 {
        right: -1% !important;
        bottom: 10% !important;
        width: 17% !important;
    }

    .l-img3 {
        left: -1% !important;
        top: 73% !important;
        width: 17% !important;
    }

    .l-img4 {
        right: -6% !important;
        top: 42% !important;
        width: 21% !important;
    }

    .l-img5 {
        bottom: 38% !important;
        left: -1% !important;
        width: 16% !important;
    }

    .l-img6 {
        left: 14% !important;
        top: 17% !important;
        width: 22% !important;
    }

    .t-img1 {
        left: 8% !important;
        top: 20% !important;
        width: 20% !important;
    }

    .t-img2 {
        right: 2% !important;
        bottom: 13% !important;
        width: 20% !important;
    }

    .t-img3 {
        left: 0% !important;
        top: 47% !important;
        width: 20% !important;
    }

    .t-img4 {
        right: 5% !important;
        top: 20% !important;
        width: 21% !important;
    }

    .university .Girl2 {
        height: 239px !important;
    }

    .u-img1 {
        left: 2% !important;
        top: 30% !important;
        width: 20% !important;
    }

    .u-img3 {
        left: -3% !important;
        bottom: 0px !important;
        width: 20% !important;
    }

    .u-img2 {
        right: -1% !important;
        bottom: 50% !important;
        width: 20% !important;
    }

    .k-img3 {
        left: 2% !important;
        bottom: 12% !important;
        width: 16% !important;
    }

    .k-img1 {
        left: 6% !important;
        top: 20% !important;
        width: 16% !important;
    }

    .k-img2 {
        right: 6% !important;
        bottom: 64% !important;
        width: 20% !important;
    }

    video::-webkit-media-controls {
        font-size: 20% !important;
    }

    .subject .card,
    .science .card,
    .lab1 .card {
        margin-bottom: 20px !important;
    }

    .col-lapse {
        margin: 0px !important;
        justify-content: center !important;
    }

    .lab .para22 {
        width: 100% !important;
        margin-top: 49px !important;
    }

    .lab .bot {
        margin-top: 0px !important;
    }

    #hub .navi {
        display: -webkit-inline-box;
        width: 88% !important;
    }

    .technology {
        height: auto !important;
    }

    .dummy-bg,
    .dummy-bg1 {
        display: none !important;
    }

    .testimonial-content .owl-carousel .owl-stage-outer {
        height: 23rem !important;
    }

    .about-lap>iframe {
        top: 8% !important;
        left: 12% !important;
        width: 76% !important;
        height: auto !important;
    }

    .ytp-large-play-button {
        left: 54% !important;
        top: 55% !important;
        width: 50px !important;
        height: 40px !important;
    }
}