@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Manrope:wght@200..800&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

* {
  margin: 0;
  padding: 0;
}

.navbar {
  background-color: #edfcf1;
}

.navbar .nav-item {
  color: black;
  font-weight: 700;
  margin: 0 7px !important;
}

.navbar .nav-item:last-child {
  margin: 0;
}

.navbar-light .navbar-nav .nav-link {
  color: black;
}

.btn {
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 82.07%);
  color: white;
  width: auto;
  height: auto;
  padding: 10px 25px;
  border: none;
  border-radius: 4px;
  font-weight: 600;
}

.technology {
  /* background-image: url('./Assets/homeframe1.png'); */
  height: 608px;
  background-size: cover;
  width: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  background-color: #edfcf1;
  /* border-radius: 0% 0% 20% 20%; */
  border-bottom-left-radius: 50% 20%;
  border-bottom-right-radius: 50% 20%;
  overflow: hidden;
}

/* .technology
 .back
  {
    margin-top: -20px;
    margin-left: -55px;
} */

.technology .h1 {
  font-family: Nunito;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  background: linear-gradient(112.3deg, #3d7851 32.27%, #3d7851 67.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  margin-bottom: 25px;
}

.technology .para1 {
  font-family: Nunito;
  font-size: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  margin-bottom: 31px;
}

.technology .small {
  position: relative;
  right: 20px;
  z-index: -2px;
}

.technology .btn {
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 82.07%);
  color: white;
  width: auto;
  height: auto;
  padding: 10px 25px;
  border: none;
  border-radius: 4px;
}

.technology .back {
  display: flex;
  flex-direction: column;
  align-self: center;
}

.trade {
  padding-top: 30px;
}

.trade .card-img-top {
  padding: 35px;
}

.trade .h3 {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
}

.trade .h1 {
  font-family: Nunito;
  font-size: 36px;
  font-weight: 700;
  line-height: 49px;
  letter-spacing: 0em;
  text-align: center;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.trade .card {
  border: none;
  width: 330px;
  border: none;
  border-radius: 25px;
  box-shadow: 4px 5px 15px rgba(61, 120, 81, 0.1), inset 4px 4px 10px white;
  position: relative;
  z-index: 1;
  margin-top: 83px;
}

.trade .card-text {
  font-family: Nunito;
  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(64, 64, 64, 1);
}

.trade .logo {
  position: absolute;
  top: 97px;
  left: -89px;
}

/* .trade .logo1 {
    width: 256px;
    height: auto;
    position: absolute;
    z-index: 3px;
    margin-top: -320px;
    right: 10px;
} */

.trade .btn {
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 82.07%);
  color: white;
  width: auto;
  height: auto;
  padding: 10px 25px;
  border: none;
  border-radius: 4px;
  text-align: center;
  margin-top: 100px;
}

.two {
  margin-top: 75px;
}

.two .h3 {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
}

.two .h1 {
  font-family: Nunito;
  font-size: 36px;
  font-weight: 700;
  line-height: 49px;
  letter-spacing: 0em;
  text-align: center;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.two .h4 {
  font-family: Nunito;
  font-weight: 600;
  font-size: 28px;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  margin-top: 60px;
}

.two .para2 {
  font-family: Nunito;
  font-weight: 400;
  font-size: 16px;
  color: #040404;
}

.two .logo4 {
  padding-left: 116px;
  padding-top: 20px;
}

.two .h5 {
  font-family: Nunito;
  font-weight: 600;
  font-size: 28px;
  line-height: 40px;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  margin-top: 60px;
}

.two .btn {
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 82.07%);
  color: white;
  width: auto;
  height: auto;
  padding: 10px 25px;
  border: none;
  border-radius: 4px;
  border-radius: 4px;
  text-align: center;
}

.about {
  /* background-image: url("../src/Assets/logo2.webp");
    background-repeat: no-repeat;
    background-size: cover; */
  background-color: #edfcf1;
}

.about .h1 {
  font-family: Nunito;
  font-size: 36px;
  font-weight: 700;
  line-height: 49px;
  letter-spacing: 0em;
  text-align: center;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.about .h4 {
  font-family: Nunito;
  font-weight: 600;
  font-size: 28px;
  line-height: 64px;
  margin-top: 60px;
  text-align: center;
  padding-top: 20px;
}

.trainer .owl-carousel .owl-item img {
  display: block;
  /* width: fit-content !important; */
}

.about .logo5 {
  width: 98vw;
}

.lap-center {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
}

.choose .h1 {
  font-family: Nunito;
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.choose .h4 {
  font-family: Nunito;
  font-weight: 600;
  font-size: 28px;
  line-height: 20px;
  margin-top: 60px;
  text-align: center;
}

.choose .box {
  margin-top: 85px;
  box-shadow: 20px 20px 20px 0px rgba(61, 120, 81, 0.1),
    inset 4px 4px 10px white;
  border-radius: 35px;
  background-color: rgba(255, 255, 255, 1);
  position: relative;
  z-index: 1;
}

.choose .row {
  padding: 60px;
}

.choose .logo7 {
  width: 76px;
  height: 76px;
  margin-bottom: 30px;
}

.choose .h3 {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 600;
  line-height: 70px;
  letter-spacing: 0em;
  text-align: left;
  color: #3d7851;
}

.choose .para3 {
  font-family: Nunito;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #040404;
  width: 302px;
}

.choose .logo8 {
  position: absolute;
  top: 4rem;
  left: -4rem;
}

.choose .logo9 {
  position: absolute;
  bottom: 8rem;
  right: -2rem;
}

.trainer {
  margin-top: 80px;
  height: 85vh;
  width: 100%;
  position: relative;
  background-color: #edfcf1;
}

.trainer .h6 {
  font-family: Nunito;
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(91, 97, 92, 1);
}

.trainer .h4 {
  font-family: Nunito;
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.trainer .row {
  padding-top: 200px;
}

.trainer .logo10 {
  width: auto !important;
  height: 100px;
  position: absolute;
  z-index: 5;
  margin-top: 100px;
  border-radius: 50% !important;
  border: 8px solid white !important;
}

.trainer .logo11 {
  display: block;
  width: 39px !important;
  height: 35px;
  margin-left: -30px;
  margin-top: 30px;
  margin-bottom: 15px;
}

.trainer .card {
  width: 357px;
  height: 300px;
  position: relative;
  left: 0px;
  z-index: 2;
  margin-left: 55px;
  border-radius: 21px;
  padding-left: 60px;
  padding-right: 20px;
}


.trainer .card-title {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: 0.29455727338790894px;
  text-align: left;
  color: rgba(37, 43, 66, 1);
}

.trainer .card-text {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.29455727338790894px;
  text-align: left;
  color: rgba(64, 64, 64, 1);
}

.trainer .para3 {
  font-family: Nunito;
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #040404;
}

.trainer .col1 {
  margin-top: -35px;
  margin-left: -40px;
}

.contact {
  margin-top: 50px;
  /* margin-bottom: 60px; */
  background-color: #3d7851;
}

.logo13 {
  position: absolute;
  right: 0;
}

.logo30 {
  position: absolute;
  left: 0;
  z-index: -5;
}

.contact .card {
  display: flex;
}

.contact .btn {
  width: 120px;
  background-color: white;
  color: rgba(61, 120, 81, 1);
}

.contact p {
  color: rgba(255, 255, 255, 1);
}

.contact .text {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  width: 488px;
  margin-top: 20px;
  margin-bottom: 40px;
}

.contact .overlay {
  margin-top: 90px;
  margin-left: 75px;
}

.contact .logo14 {
  border: none;
}

#hub {
  background-color: #213626;
  position: relative;
}

#hub .container {
  background-color: #213626;
  color: white;
}

/* 
#hub .vector1,
.vector2,
.vector3,
.vector4,
.vector5 {
    margin-right: 20px;
    margin-top: -149px;
}

#hub .col {
    margin-top: -35px;
} */
.template .h1 {
  font-family: Nunito;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #77c48c;
}

.template .para4 {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #040404;
  width: 78%;
  margin: auto;
}

.template {
  margin-top: 40px;
}

.template .color {
  background-color: rgba(118, 184, 138, 1);
  height: 150px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  position: relative;
}

.template .owl-carousel .owl-item img {
  width: auto !important;
}

.template .owl-carousel.owl-drag .owl-item {
  height: 84px;
  background-color: white;
  display: flex;
  justify-content: center;
  border-radius: 8px;
}

.template .owl-carousel.owl-drag .owl-item>div {
  display: flex;
  align-items: center;
}

.plat .h1 {
  font-family: Nunito;
  font-size: 55px;
  font-weight: 700;
  line-height: 65px;
  letter-spacing: 0em;
  text-align: left;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  margin-bottom: 25px;
}

.plat .para1 {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #040404;
  margin-bottom: 25px;
}

.plat .btn {
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 82.07%);
  color: white;
  width: auto;
  height: auto;
  padding: 10px 25px;
  border: none;
  border-radius: 4px;
}

.list .title {
  font-family: Nunito;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 30px;
}

.list .vector1 {
  width: 30px;
  height: 113px;
  margin-top: 70px;
  margin-left: 20px;
}

.list .col {
  margin-left: 20px;
}

.list .img1 {
  margin-left: 15px;
}

.list .row {
  display: flex;
  justify-content: center;
}

.list .learn {
  color: rgba(119, 196, 140, 1);
  text-decoration: none !important;
}

.banner {
  margin-top: 70px;
}

.banner .subtitle {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(100, 100, 100, 1);
}

.banner .title {
  font-family: Nunito;
  font-size: 43px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  margin-bottom: 40px;
}

.banner .para10 {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #040404;
}

.banner .btn {
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 82.07%);
  color: white;
  width: auto;
  height: auto;
  padding: 10px 25px;
  border: none;
  border-radius: 4px;
}

.features {
  background-color: rgba(249, 249, 249, 1);
  padding-bottom: 10px;
}

.features .subtitle {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(100, 100, 100, 1);
}

.features .title {
  font-family: Nunito;
  font-size: 43px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.features .banner-text {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(91, 97, 92, 1);
  margin-top: 30px;
}

.features .para11 {
  font-family: Nunito;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #040404;
}

.features .row {
  margin-top: 50px;
  margin-bottom: 50px;
  padding-top: 50px;
}

.cloud .subtitle {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(100, 100, 100, 1);
  /* margin-top: 40px; */
}

.cloud .title {
  font-family: Nunito;
  font-size: 43px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  margin-bottom: 30px;
}

.cloud .para11 {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #040404;
  margin-bottom: 30px;
}

.cloud .btn {
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 82.07%);
  color: white;
  width: auto;
  height: auto;
  padding: 10px 25px;
  border: none;
  border-radius: 4px;
}

.target {
  margin-top: 70px;
  margin-bottom: 100px;
}

.target .img5 {
  width: 76px;
  height: 76px;
  margin-left: 12px;
}

.target .logo {
  width: auto;
  height: auto;
  position: absolute;
  left: 200px;
  margin-top: -50px;
}

.target .logo1 {
  width: auto;
  height: auto;
  position: absolute;
  z-index: 3px;
  margin-top: -169px;
  right: 245px;
}

.target .card {
  width: 330px;
  height: 330px;
  padding: 20px;
  border-radius: 26px;
  margin-right: 40px;
  position: relative;
  z-index: 5;
  box-shadow: 20px 20px 20px 0px rgba(61, 120, 81, 0.1),
    inset 4px 4px 10px white;
}

.target .title {
  font-family: Nunito;
  font-size: 36px;
  font-weight: 700;
  line-height: 49px;
  letter-spacing: 0em;
  text-align: center;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  margin-bottom: 60px;
}

.target .row {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.target .banner-text {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(61, 120, 81, 1);
  margin-top: 30px;
}

.target .para12 {
  font-family: Nunito;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #040404;
}

.tech .h1 {
  font-family: Nunito;
  font-size: 44px;
  font-weight: 700;
  line-height: 65px;
  letter-spacing: 0em;
  text-align: left;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  margin-bottom: 25px;
}

.tech .para1 {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #040404;
  margin-bottom: 25px;
}

.tech .btn {
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 82.07%);
  color: white;
  width: auto;
  height: auto;
  padding: 10px 25px;
  border: none;
  border-radius: 4px;
}

.growth .subtitle {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(100, 100, 100, 1);
  margin-top: 40px;
}

.growth .title {
  font-family: Nunito;
  font-size: 43px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  margin-bottom: 80px;
}

.growth .col {
  display: flex;
  justify-content: center;
}

.growth .para15 {
  font-family: Nunito;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  width: 500px;
  color: #040404;
}

.growth .num {
  margin-right: 20px;
  margin-top: -5px;
}

.growth .btn {
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 82.07%);
  color: white;
  width: auto;
  height: auto;
  padding: 10px 25px;
  border: none;
  border-radius: 4px;
  font-weight: 600;
}

.growth .bot {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  position: relative;
  z-index: 1;
}

.magnet .img7 {
  width: 76px;
  height: 76px;
  margin-bottom: 40px;
}

.magnet .card {
  width: 330px;
  height: 440px;
  padding: 30px;
  box-shadow: 20px 20px 20px 0px rgba(61, 120, 81, 0.1),
    inset 4px 4px 10px white;
  border-radius: 26px;
  z-index: 1;
}

.magnet .title1 {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 700;
  text-align: left;
  color: rgba(61, 120, 81, 1);
  margin-bottom: 30px;
}

.magnet .para18 {
  font-family: Nunito;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #040404;
}

.magnet .logo8 {
  position: absolute;
  margin-left: -81px;
  margin-top: -70px;
}

.magnet .logo1 {
  position: absolute;
  margin-top: -163px;
  z-index: -1;
  right: 30px;
}

.magnet .subtitle {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(100, 100, 100, 1);
  margin-top: 40px;
}

.magnet .title {
  font-family: Nunito;
  font-size: 43px;
  font-weight: 700;
  text-align: center;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #77c48c 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  margin-bottom: 80px;
}

.magnet .btn {
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 82.07%);
  color: white;
  width: auto;
  height: auto;
  padding: 10px 25px;
  border: none;
  border-radius: 4px;
  font-weight: 600;
}

.magnet .bot {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}

.gun {
  margin-top: 70px;
}

.gun .subtitle {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(100, 100, 100, 1);
}

.gun .title {
  font-family: Nunito;
  font-size: 36px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  margin-bottom: 80px;
}

.gun .title1 {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  width: 500px;
}

.para1 {
  color: #040404;
}

.gun .para19 {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  width: 500px;
  text-align: left;
  color: #040404;
  margin-bottom: 30px;
}

.gun .col {
  display: flex;
  justify-content: center;
}

.gun .num {
  margin-right: 20px;
}

.gun .btn {
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 82.07%);
  color: white;
  width: auto;
  height: auto;
  padding: 10px 25px;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  margin-bottom: 10px;
}

.gun .bot {
  display: flex;
  justify-content: center;
}

.cartoon .subtitle {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(100, 100, 100, 1);
  margin-top: 60px;
}

.cartoon .title {
  font-family: Nunito;
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  margin-bottom: 30px;
}

.cartoon .para20 {
  font-family: Nunito;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #040404;
  margin-bottom: 40px;
}

.cartoon .btn {
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 82.07%);
  color: white;
  width: auto;
  height: auto;
  padding: 10px 25px;
  border: none;
  border-radius: 4px;
  font-weight: 600;
}

.Girl {
  height: 605px;
}

.video {
  position: absolute;
  top: 60px;
}

.video2 {
  position: relative;
}

.pop {
  position: absolute;
  left: 111px;
  top: 44px;
}

iframe {
  width: 73%;
  height: 76%;
}

.ytp-chrome-top,
.ytp-chrome-bottom {
  display: none;
}

.ytp-title-channel {
  display: none;
}

.trainer .col1 {
  margin-top: 0px;
  margin-left: 0px;
}

.trainer .row {
  padding-top: 20px;
}

.trainer {
  height: auto;
}

.develop .h1 {
  font-family: Nunito;
  font-size: 47px;
  font-weight: 700;
  line-height: 55px;
  letter-spacing: 0em;
  text-align: left;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  margin-bottom: 25px;
  margin-top: 60px;
}

.develop .para1 {
  font-family: Nunito;
  font-size: 17px;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #040404;
  margin-bottom: 30px;
}

.develop .btn {
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 82.07%);
  color: white;
  width: auto;
  height: auto;
  padding: 10px 25px;
  border: none;
  border-radius: 4px;
  font-weight: 600;
}

.innovation {
  margin-top: 70px;
}

.innovation .subtitle {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 700;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(100, 100, 100, 1);
}

.innovation .title {
  font-family: Nunito;
  font-size: 36px;
  font-weight: 700;
  line-height: 49px;
  letter-spacing: 0em;
  text-align: left;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  margin-bottom: 30px;
}

.innovation .para21 {
  font-family: Nunito;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #040404;
  margin-bottom: 40px;
}

.innovation .btn {
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 82.07%);
  color: white;
  width: fit-content;
  height: 45px;
  border: none;
  border-radius: 4px;
}

.lab {
  background-color: rgba(249, 249, 249, 1);
}

.lab .row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0px;
  padding-top: 35px;
  padding-bottom: 20px;
}

.lab .title {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(100, 100, 100, 1);
}

.lab .img11 {
  margin-bottom: 20px;
}

.lab .img12 {
  margin-bottom: 20px;
}

.lab .con {
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 82.07%);
  height: 350px;
  display: flex;
  align-items: center;
}

.lab .box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lab .para22 {
  font-family: Nunito;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  width: 73%;
  text-align: center;
  color: #fff;
}

.lab .btn {
  color: #4ca363;
  width: 220px;
  height: 45px;
  border: none;
  border-radius: 4px;
}

.lab .bot {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.support .h6 {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(100, 100, 100, 1);
}

.support .h1 {
  font-family: Nunito;
  font-size: 36px;
  font-weight: 700;
  line-height: 49px;
  letter-spacing: 0em;
  text-align: left;
  color: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 82.07%);
}

.support .para22 {
  font-family: Nunito;
  font-size: 17px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #040404;
}

.support .btn {
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 82.07%);
  color: white;
  width: auto;
  height: auto;
  padding: 10px 25px;
  border: none;
  border-radius: 4px;
  font-weight: 600;
}

.support .card {
  width: 300px;
  height: 335px;
  border-radius: 26px;
  box-shadow: 20px 20px 20px 0px rgba(61, 120, 81, 0.1),
    inset 4px 4px 10px white;
}

.support .img16 {
  width: 76px;
  width: 76px;
}

.support .col1 {
  position: relative;
  top: 0;
}

.support .logo {
  position: absolute;
  top: -55px;
  left: -20px;
}

.support .col2 {
  position: relative;
  z-index: 3;
}

.support .logo30 {
  position: absolute;
  left: 160px;
  top: 160px;
  z-index: -1;
}

.tar .row {
  display: flex;
  justify-content: center;
}

.tar .title {
  font-family: Nunito;
  font-size: 36px;
  font-weight: 700;
  line-height: 49px;
  letter-spacing: 0em;
  text-align: center;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.tar .img20 {
  width: 76px;
  height: 76px;
}

.tar .card:hover,
.tar .card-one:hover,
.lab1 .card:hover,
.science .card:hover,
.subject .card:hover,
.trade .card:hover,
.target .card:hover,
.magnet .card:hover,
.benefit .card:hover,
.benefit1 .card:hover,
.team .card:hover,
.support .card:hover,
.benefit2 .card:hover {
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 82.07%);
  /* border-width: 4px;
    border-style: solid;
    border-image: linear-gradient(to right,  #3D7851, #77C48C) 1; */
}

.tar .card:hover p,
.tar .card-one:hover p,
.lab1 .card:hover p,
.science .card:hover p,
.subject .card:hover p,
.trade .card:hover p,
.target .card:hover p,
.magnet .card:hover p,
.benefit .card:hover p,
.benefit1 .card:hover p,
.team .card:hover p,
.support .card:hover p,
.benefit2 .card:hover p {
  color: #ffffff;
}

.tar .card:hover p:first-child,
.tar .card-one:hover p:first-child,
.lab1 .card:hover p:first-child,
.science .card:hover p:first-child,
.subject .card:hover p:first-child,
.target .card:hover p:first-child,
.magnet .card:hover p:first-child,
.benefit1 .card:hover p:first-child,
.team .card:hover p:first-child {
  background: linear-gradient(90.3deg, #fff 8.27%, #fff 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.tar .card {
  width: 450px;
  height: 430px;
  border-radius: 0px 50px 0px 50px;
  /* background: linear-gradient(90.3deg, #3D7851 8.27%, #77C48C 82.07%); */
}

.tar .card-one {
  width: 450px;
  height: 430px;
  border-radius: 50px 0px 50px 0px;
}

.tar .banner-text {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.tar .card-text {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.tar .para25 {
  font-family: Nunito;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #040404;
}

.tar .para26 {
  font-family: Nunito;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #040404;
  /* color: rgba(255, 255, 255, 1); */
}

.tar .btn {
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 82.07%);
  color: white;
  width: auto;
  height: auto;
  padding: 10px 25px;
  border: none;
  border-radius: 4px;
  font-weight: 600;
}

.tar .bot {
  display: flex;
  justify-content: center;
}

.digital .h6 {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(91, 97, 92, 1);
}

.digital .h1 {
  font-family: Nunito;
  font-size: 36px;
  font-weight: 700;
  line-height: 49px;
  letter-spacing: 0em;
  text-align: left;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.digital .para27 {
  font-family: Nunito;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #040404;
}

.digital .btn {
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 82.07%);
  color: white;
  width: auto;
  height: auto;
  padding: 10px 25px;
  border: none;
  border-radius: 4px;
  font-weight: 600;
}

.digital .bot {
  display: flex;
  justify-content: center;
}

.corporation .h1 {
  font-family: Nunito;
  font-size: 47px;
  font-weight: 700;
  line-height: 55px;
  letter-spacing: 0em;
  text-align: left;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  margin-bottom: 25px;
}

.corporation .para1 {
  font-family: Nunito;
  font-size: 17px;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #040404;
  margin-bottom: 30px;
}

.corporation .btn {
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 82.07%);
  color: white;
  width: auto;
  height: auto;
  padding: 10px 25px;
  border: none;
  border-radius: 4px;
  font-weight: 600;
}

.lab1 .img28 {
  width: 76px;
  height: 76px;
  margin-bottom: 40px;
}

.lab1 .card {
  width: 330px;
  height: 330px;
  box-shadow: 20px 20px 20px 0px rgba(61, 120, 81, 0.1),
    inset 4px 4px 10px white;
  border-radius: 26px;
  z-index: 1;
}

.lab1 .card:hover {
  width: 330px;
  height: 330px;
  box-shadow: 20px 20px 20px 0px rgba(61, 120, 81, 0.1),
    inset 4px 4px 10px white;
  border-radius: 26px;
  z-index: 1;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 82.07%);
}

.lab1 .card:hover .lab1 .col4,
.col7 {
  position: relative;
}

.lab1 .title1 {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(61, 120, 81, 1);
}

.lab1 .para35 {
  font-family: Nunito;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #040404;
}

.lab1 .logo8 {
  position: absolute;
  top: -69px;
  left: -75px;
}

.lab1 .logo1 {
  position: absolute;
  right: -69px;
  top: 171px;
}

.lab1 .subtitle {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(100, 100, 100, 1);
}

.lab1 .title {
  font-family: Nunito;
  font-size: 43px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.lab1 .btn {
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 82.07%);
  color: white;
  width: 220px;
  height: 45px;
  border: none;
  border-radius: 4px;
}

.lab1 .bot {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}

.image {
  display: flex;
  justify-content: center;
}

.image3 {
  display: flex;
  justify-content: center;
}

.image1 .subtitle {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(100, 100, 100, 1);
}

.image1 .title {
  font-family: Nunito;
  font-size: 43px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  margin-bottom: 80px;
}

.design .h6 {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(91, 97, 92, 1);
}

.design .h1 {
  font-family: Nunito;
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.design .para27 {
  font-family: Nunito;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #040404;
}

.design .btn {
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 82.07%);
  color: white;
  width: auto;
  height: auto;
  padding: 10px 25px;
  border: none;
  border-radius: 4px;
  font-weight: 600;
}

.benefit .img30 {
  width: 76px;
  height: 76px;
  margin-bottom: 40px;
}

.benefit .card {
  width: 256px;
  height: 256px;
  box-shadow: 20px 20px 20px 0px rgba(61, 120, 81, 0.1),
    inset 4px 4px 10px white;
  border-radius: 26px;
  z-index: 1;
}

.benefit .col4,
.col7 {
  position: relative;
}

.benefit .para35 {
  font-family: Nunito;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #040404;
}

.benefit .logo8 {
  position: absolute;
  top: -69px;
  left: -75px;
}

.benefit .logo1 {
  position: absolute;
  right: -69px;
  top: 96px;
}

.benefit .subtitle {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(100, 100, 100, 1);
}

.benefit .title {
  font-family: Nunito;
  font-size: 43px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.partner .h6 {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(91, 97, 92, 1);
}

.partner .h1 {
  font-family: Nunito;
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.partner .para37 {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #040404;
}

.partner .color {
  background-color: rgba(118, 184, 138, 1);
  height: 150px;
}

.partner .logo24 {
  width: 211px;
  height: 84px;
}

.partner .container {
  width: 53%;
}

.partner .btn {
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 82.07%);
  color: white;
  width: 220px;
  height: 45px;
  border: none;
  border-radius: 4px;
}

.partner .bot {
  display: flex;
  justify-content: center;
}

.university .h1 {
  font-family: Nunito;
  font-size: 47px;
  font-weight: 700;
  line-height: 55px;
  letter-spacing: 0em;
  text-align: left;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  margin-bottom: 25px;
}

.university .para1 {
  font-family: Nunito;
  font-size: 17px;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #040404;
  margin-bottom: 30px;
}

.university .btn {
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 82.07%);
  color: white;
  width: 220px;
  height: 45px;
  border: none;
  border-radius: 4px;
}

.science .img28 {
  width: 76px;
  height: 76px;
  margin-bottom: 40px;
}

.science .card {
  width: 330px;
  height: 330px;
  box-shadow: 20px 20px 20px 0px rgba(61, 120, 81, 0.1),
    inset 4px 4px 10px white;
  border-radius: 26px;
  z-index: 1;
}

.science .col4,
.col7 {
  position: relative;
}

.science .title1 {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(61, 120, 81, 1);
}

.science .para35 {
  font-family: Nunito;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #040404;
}

.science .logo8 {
  position: absolute;
  top: -69px;
  left: -75px;
}

.science .logo1 {
  position: absolute;
  right: -42px;
  top: 171px;
}

.science .subtitle {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(100, 100, 100, 1);
}

.science .title {
  font-family: Nunito;
  font-size: 43px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.science .btn {
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 82.07%);
  color: white;
  width: 220px;
  height: 45px;
  border: none;
  border-radius: 4px;
}

.science .bot {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}

.image2 {
  display: flex;
  justify-content: center;
}

.image6 {
  display: flex;
  justify-content: center;
}

.image4 .subtitle {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(100, 100, 100, 1);
}

.image4 .title {
  font-family: Nunito;
  font-size: 43px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  margin-bottom: 80px;
}

.drive .h6 {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(91, 97, 92, 1);
}

.drive .h1 {
  font-family: Nunito;
  font-size: 36px;
  font-weight: 700;
  line-height: 49px;
  letter-spacing: 0em;
  text-align: left;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.drive .para27 {
  font-family: Nunito;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #040404;
}

.drive .btn {
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 82.07%);
  color: white;
  width: 220px;
  height: 45px;
  border: none;
  border-radius: 4px;
}

.benefit1 .img31,
.benefit1 .img30 {
  width: 76px;
  height: 76px;
  margin-bottom: 40px;
}

.benefit1 .card {
  width: 256px;
  height: 256px;
  box-shadow: 20px 20px 20px 0px rgba(61, 120, 81, 0.1),
    inset 4px 4px 10px white;
  border-radius: 26px;
  z-index: 1;
}

.benefit1 .col4,
.col7 {
  position: relative;
}

.benefit1 .para36 {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #040404;
}

.benefit1 .logo8 {
  position: absolute;
  top: -69px;
  left: -75px;
}

.benefit1 .logo1 {
  position: absolute;
  right: -42px;
  top: 96px;
}

.benefit1 .subtitle {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(100, 100, 100, 1);
}

.benefit1 .title {
  font-family: Nunito;
  font-size: 43px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.training .h1 {
  font-family: Nunito;
  font-size: 47px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  margin-bottom: 25px;
}

.training .para38 {
  font-family: Nunito;
  font-size: 17px;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #040404;
  margin-bottom: 30px;
}

.training .btn {
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 82.07%);
  color: white;
  height: 45px;
  border: none;
  border-radius: 4px;
}

.subject .img32 {
  width: 76px;
  height: 76px;
  margin-bottom: 40px;
}

.subject .card {
  width: 330px;
  height: 330px;
  box-shadow: 20px 20px 20px 0px rgba(61, 120, 81, 0.1),
    inset 4px 4px 10px white;
  border-radius: 26px;
  z-index: 1;
}

.subject .col4,
.col7 {
  position: relative;
}

.subject .title1 {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(61, 120, 81, 1);
}

.subject .para38 {
  font-family: Nunito;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #040404;
}

.subject .logo8 {
  position: absolute;
  top: -69px;
  left: -75px;
}

.subject .logo1 {
  position: absolute;
  right: -69px;
  top: 171px;
}

.subject .subtitle {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(100, 100, 100, 1);
}

.subject .title {
  font-family: Nunito;
  font-size: 43px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.subject .btn {
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 82.07%);
  color: white;
  width: 220px;
  height: 45px;
  border: none;
  border-radius: 4px;
}

.subject .bot {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}

.image5 {
  display: flex;
  justify-content: center;
}

.image9 {
  display: flex;
  justify-content: center;
}

.image7 .subtitle {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(100, 100, 100, 1);
}

.image7 .title {
  font-family: Nunito;
  font-size: 43px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  margin-bottom: 80px;
}

.touch .h6 {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(91, 97, 92, 1);
}

.touch .h1 {
  font-family: Nunito;
  font-size: 36px;
  font-weight: 700;
  line-height: 49px;
  letter-spacing: 0em;
  text-align: left;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.touch .para39 {
  font-family: Nunito;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #040404;
}

.touch .btn {
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 82.07%);
  color: white;
  height: 45px;
  border: none;
  border-radius: 4px;
}

.benefit2 .img32 {
  width: 76px;
  height: 76px;
  margin-bottom: 40px;
}

.benefit2 .card {
  width: 256px;
  height: 256px;
  box-shadow: 20px 20px 20px 0px rgba(61, 120, 81, 0.1),
    inset 4px 4px 10px white;
  border-radius: 26px;
  z-index: 1;
}

.benefit2 .col4,
.col7 {
  position: relative;
}

.benefit2 .para36 {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #040404;
}

.benefit2 .logo8 {
  position: absolute;
  top: -69px;
  left: -75px;
}

.benefit2 .logo1 {
  position: absolute;
  right: -69px;
  top: 96px;
}

.benefit2 .subtitle {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(100, 100, 100, 1);
}

.benefit2 .title {
  font-family: Nunito;
  font-size: 43px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

/* .about1 {
    background-image: url('../Assets/aboutusbg.webp');
    height: 90vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
} */

.about1 .h1 {
  font-family: Nunito;
  font-size: 36px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.about1 .para40 {
  font-family: Nunito;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #040404;
}

.about1 .btn {
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 82.07%);
  color: white;
  font-weight: 600;
  width: auto;
  height: auto;
  padding: 10px 25px;
  border: none;
  border-radius: 4px;
}

.ourlogo .h6 {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(91, 97, 92, 1);
}

.ourlogo .h1 {
  font-family: Nunito;
  font-size: 36px;
  font-weight: 700;
  line-height: 49px;
  letter-spacing: 0em;
  text-align: left;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.ourlogo .para41 {
  font-family: Nunito;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #040404;
}

.ourlogo .btn {
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 82.07%);
  color: white;
  width: auto;
  height: auto;
  padding: 10px 25px;
  border: none;
  border-radius: 4px;
  font-weight: 600;
}

.message {
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 82.07%);
  width: 100%;
}

.message .subtitle {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 0;
}

.message .title {
  font-family: Nunito;
  font-size: 36px;
  font-weight: 700;
  line-height: 49px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}

.message .para42 {
  font-family: Nunito;
  font-size: 16px;
  line-height: 22px;
  width: 561px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
}

.message .btn {
  background-color: rgba(255, 255, 255, 1);
  color: #4ca363;
  width: auto;
  height: 45px;
  border: none;
  border-radius: 4px;
}

.message .col1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.team .img35 {
  width: 76px;
  height: 76px;
}

.team .logo {
  width: auto;
  height: auto;
  position: absolute;
  left: 200px;
}

.team .logo1 {
  width: 256px;
  height: 277px;
  position: absolute;
  z-index: 3px;
  margin-top: -231px;
  right: 241px;
}

.team .card {
  width: 376px;
  height: 290px;
  padding: 20px;
  border-radius: 26px;
  box-shadow: 20px 20px 20px 0px rgba(61, 120, 81, 0.1),
    inset 4px 4px 10px white;
}

.team .title {
  font-family: Nunito;
  font-size: 36px;
  font-weight: 700;
  line-height: 49px;
  letter-spacing: 0em;
  text-align: center;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  margin-bottom: 60px;
}

.team .row {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.team .banner-text {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(61, 120, 81, 1);
}

.team .para43 {
  font-family: Nunito;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #040404;
}

.team .col1 {
  position: relative;
  top: 0;
}

.team .logo {
  position: absolute;
  top: -66px;
  left: -70px;
}

.team .col2 {
  position: relative;
  z-index: 3;
}

.team .logo31 {
  position: absolute;
  left: 232px;
  top: 130px;
  z-index: -1;
}

.global .trip {
  width: 56%;
  margin-bottom: 50px;
}

.global .h6 {
  font-family: Nunito;
  text-align: center;
  color: rgba(91, 97, 92, 1);
}

.global .h1 {
  font-family: Nunito;
  text-align: center;
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 34.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.global .para44 {
  font-family: Nunito;
  text-align: center;
  color: #040404;
}

.global .img36 {
  width: 239px;
  height: 239px;
}

.global .size {
  text-align: center;
}

.global .col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.global .h6 {
  font-family: Nunito;
}

.global .para45 {
  font-family: Nunito;
  color: #040404;
}

.nav2 {
  height: 100px;
  border-bottom-left-radius: 100% 35%;
  border-bottom-right-radius: 100% 35%;
}

.bullet-header {
  background: linear-gradient(94.05deg, #3d7851 6.03%, #3d7851 95.33%);
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px 28px 10px 10px;
  color: #fff;
  border-radius: 0% 25px 25px 0%;
}

.grad-color,
.wysiwyg h3 {
  background: linear-gradient(112.3deg, #3d7851 32.27%, #3d7851 67.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-weight: 700;
  font-family: "Nunito";
}

.btn-primary {
  background: linear-gradient(92.13deg, #3d7851 0.44%, #3d7851 99.48%);
  border: none;
  padding: 10px 14px;
  border-radius: 6px;
}

.btn-primary:hover {
  background: linear-gradient(92.13deg, #3d7851 0.44%, #3d7851 99.44%);
}

.nunito {
  font-family: Nunito;
}

.green {
  color: #000000;
}

.gray {
  color: #5b615c;
}

.fw-700 {
  font-weight: 700;
}

.grad-color2 {
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 82.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-weight: 700;
  font-family: "Nunito";
}

.formk label {
  color: #7f7f7f;
}

.error-text {
  color: red;
}

.vr {
  border: 1px solid #e1dbdb;
}

.sml {
  font-size: 15px;
}

.bullet-header-2 {
  background: linear-gradient(94.05deg, #3d7851 6.03%, #3d7851 95.33%);
  width: -moz-fit-content;
  width: fit-content;
  padding: 3px 8px 3px 3px;
  color: #fff;
  border-radius: 0% 25px 25px 0%;
}

.blog-card {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 13px;
  border-radius: 20px;
}

.blog-card .blog-cnt {
  font-size: 12px;
  height: 55px;
  color: #040404;
}

.blog-card img {
  width: 100% !important;
  height: 175px;
}

.gold {
  color: #fcb900;
}

.social-media-k {
  background-color: #3d7851;
  height: 30px;
  width: 30px;
  text-align: center;
  color: white;
}

.sticker {
  display: flex;
  align-items: center;
}

.number {
  background-color: #3d7851;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
}

.contact-k label {
  color: #3d7851;
  font-size: 12px;
  font-weight: bold;
}

.contact-k .form-control {
  font-size: 12px;
}

.contact-k .error-text {
  font-size: 12px;
  margin: 0px;
}

.btn-submit-ct {
  background: linear-gradient(92.13deg, #3d7851 0.44%, #3d7851 99.48%);
  border: none;
  padding: 7px 14px;
  border-radius: 25px;
  width: -moz-fit-content;
  width: fit-content;
  color: #fff;
  margin-top: 10px;
}

.btn-submit-ct:hover {
  transform: scale(1.2);
  color: #000000;
}
.btn-submit-diabled {
 
  padding: 2px 14px;
  border-radius: 25px;
 
  border-color: #3D7851;
  width: -moz-fit-content;
  width: fit-content;
  color: #000;
}

.btn-submit-diabled:hover {
  transform: scale(1.2);
  color: #3d7851;
}

.accordion-button {
  color: #3d7851;
  font-size: 20px;
  /* border: 1px solid #77C48C !important; */
}

.accordion-button:not(.collapsed) {
  color: #ffffff;
  font-weight: 600;
  background: linear-gradient(92.13deg, #3d7851 0.44%, #3d7851 99.48%);
}

.accordion-button:not(.collapsed)::after {
  background-image: url("../Assets/arr-up.webp");
  transform: rotate(-180deg);
}

.accordion-button::after {
  background-image: url("../Assets/arr-down.webp");
}

.accordion-button:focus {
  border: none;
  box-shadow: none;
}

.accordion-item {
  border: 1px solid #77c48c !important;
}

.ac-item-1 {
  margin: 20px 0px;
}

.ac-body-1 {
  font-size: 15px;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  position: absolute;
  border-radius: 10px;
  padding: 12px 20px;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: transparent;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #3d7851;
}

.contact-k {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 10px;
  border-radius: 20px;
}

.FAQ {
  margin-top: 120px;
}

.previous,
.next {
  cursor: pointer;
}

.previous {
  border-right: 1px solid rgb(202, 202, 202);
}

.h-top {
  margin-top: 80px;
}

.h-top2 {
  margin-top: 180px;
}

#faq {
  scroll-margin-top: 180px;
}

.nav-link:hover,
.nav-link:focus,
.nav-link.active {
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 82.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-weight: 700;
}

.nav-link.active>.dropdown-menu>.nav-item .nav-link.dropdown-toggle {
  background: linear-gradient(90.3deg, #3d7851 8.27%, #3d7851 82.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-weight: 700;
}

#hub .nav-link:hover {
  color: #2fbc80 !important;
  background: none;
  -webkit-text-fill-color: #77c48c;
}

.john .lg {
  width: 16px;
  height: 16px;
}

.john .col1,
.col {
  font-size: x-small;
}

.john .col2 {
  width: 66px;
}

#hub .h3 {
  font-family: Nunito;
  font-size: 40px;
  font-weight: 700;
  line-height: 58px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: -23px;
}

#hub .mail {
  display: flex;
  align-items: center;
}

#hub .navi {
  display: -webkit-inline-box;
  width: 47%;
}

#hub .logo16 {
  width: 25px;
  height: 25px;
  object-fit: contain;
}

#hub .control {
  font-family: Nunito;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 0px;
}

#hub .navigation {
  font-family: Nunito;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}

#hub .social {
  display: flex;
  justify-content: end;
}

#hub .last {
  font-family: Nunito;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin-right: 20px;
  color: rgba(255, 255, 255, 0.65);
}

#hub .middle {
  font-family: Nunito;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin-right: 20px;
  color: rgba(255, 255, 255, 0.65);
}

#hub .one {
  font-family: Nunito;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(255, 255, 255, 0.65);
}

#hub .down {
  display: flex;
  justify-content: center;
}

hr:not([size]) {
  height: 4px;
  color: #c3baba;
}

#hub .icon {
  width: 75px;
  height: 25px;
}

.nav-link {
  color: white;
  font-size: medium;
  padding: 4px;
}

#hub h5 {
  font-family: Nunito;
  font-weight: 700;
  text-align: left;
}

#hub .logo16 {
  margin-right: 10px;
}

.btn-a1 {
  width: fit-content;
  color: #3d7851;
  border: 1px solid #fff;
  padding: 10px 20px;
  background-color: #fff;
  border-radius: 6px;
  font-weight: 600;
}

.btn-a1:hover {
  color: #fff;
  border: 1px solid #3d7851;
  background-color: #4ca363;
}

video {
  position: absolute;
  left: 13%;
  width: 74%;
  height: 78%;
  top: 7%;
  background-color: rgb(0, 0, 0);
}

/* video::-webkit-media-controls-panel {
    display: none !important;
    opacity: 1 !important;
} */

.video-abt {
  position: absolute;
  width: 76.5%;
  height: 72%;
  background-color: white;
  top: 7%;
  left: 16%;
}

.about-lap {
  position: relative;
}

.navbar-expand-lg .navbar-collapse {
  justify-content: end;
}

.main-owl .owl-carousel .owl-nav.disabled {
  display: block !important;
  width: 20%;
  position: absolute;
  margin-top: 0px;
  left: -310px;
  top: 129px;
}

.main-owl .owl-carousel .owl-nav button.owl-prev,
.main-owl .owl-carousel .owl-nav button.owl-next {
  background-color: #fff !important;
  border: 1px solid #3d7851;
  border-radius: 50%;
  color: #3d7851 !important;
  font: message-box;
  font-size: 30px !important;
  height: 47px;
  width: 47px;
}

.shadow-a {
  position: relative;
}

.shadow-a1 {
  position: absolute;
  background: linear-gradient(270deg,
      rgba(119, 196, 140, 0) 27.4%,
      #77c48c 75%);
  width: 100px;
  top: 0px;
  height: 100%;
  z-index: 2;
}

.shadow-a2 {
  position: absolute;
  background: linear-gradient(270deg,
      #77c48c 31.4%,
      rgba(119, 196, 140, 0) 100%),
    linear-gradient(270deg, #121212 31.4%, rgba(18, 18, 18, 0) 31.41%);

  width: 100px;
  top: 0px;
  right: 0px;
  height: 100%;
  z-index: 2;
}

.trade-con {
  position: relative;
}

.trade-con .logo1 {
  position: absolute;
  bottom: 84px;
  right: -3%;
}

.two .row>div:first-child {
  padding: 0px 73px;
}

.two .row>div:first-child>p:first-child {
  font-size: 22px;
}

.chs-con {
  position: relative;
}

.col-lapse {
  margin: 0px 75px;
}

.list .col-lg-2 {
  width: 20%;
}

.list .img1 {
  width: fit-content;
}

.list .col-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.para36 {
  font-size: 16px !important;
  color: #040404;
}

.btn-price {
  background: linear-gradient(102.36deg, #3d7851 0%, #3d7851 108.99%);
  width: fit-content;
  padding: 8px 18px;
  border-radius: 50px;
  font-size: 22px;
  height: auto;
  border: none;
  color: #fff;
}

.btn-price-2 {
  border: none;
  background: white;
  font-size: 22px;
  padding: 8px 18px;
  border-radius: 50px;
}

.btn-grp {
  border: 1px solid #77c48c;
  padding: 4px;
  width: fit-content;
  border-radius: 50px;
  display: flex;
}

/* .btn-grp button:hover {
  background: linear-gradient(102.36deg, #77c48c 0%, #3d7851 108.99%);
  width: fit-content;
  padding: 8px 18px;
  border-radius: 50px;
  font-size: 22px;
  height: auto;
  border: none;
  color: #fff;
} */

.price-sub {
  background: linear-gradient(80.3deg, #3d7851 48.27%, #3d7851 56.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-weight: 700;
  text-align: center;
}

.sub-card {
  border: 1px solid #4ca363;
  padding: 20px 80px;
  border-radius: 20px;
  position: relative;
  box-shadow: 0px 15.737430572509766px 43.715084075927734px 0px #3d78511a;
}

.price-amt {
  font-weight: 700;
  color: #040404;
  font-size: 73px;
}

.price-prd {
  background: linear-gradient(80.3deg, #3d7851 96.27%, #3d7851 56.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-weight: 700;
  font-size: 26px;
}

.btn-grp-2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-grp-2 button {
  width: 36px;
  height: 29px;
  border: 1px solid #77c48c;
  border-radius: 8px;
  color: #77c48c;
  background-color: #fff;
}

.btn-grp-2 p {
  background: linear-gradient(94.33deg, #3d7851 0.09%, #3d7851 99.1%);
  width: 37px;
  height: 29px;
  text-align: center;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-grp-2 button:first-child {
  position: relative;
  left: 7px;
}

.btn-grp-2 button:last-child {
  position: relative;
  left: -7px;
}

.batch {
  font-weight: 600;
  color: #040404;
}

.sub-card .sticker {
  display: flex;
  align-items: center;
}

.sub-card .number {
  background: linear-gradient(138.24deg, #3d7851 9.8%, #3d7851 83.65%);
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
}

.price-list {
  color: #7f7f7f;
  font-size: 20px;
  font-weight: 500;
}

.btn-subr {
  width: 100%;
  height: auto;
  margin-top: 55px;
  margin-bottom: 15px;
  padding: 10px 0px;
}

.sub-card .lic {
  background: linear-gradient(94.05deg, #3d7851 6.03%, #3d7851 95.33%);
  color: #fff;
  width: fit-content;
  padding: 6px 15px;
  border-radius: 0px 30px 30px 0px;
  position: absolute;
  top: 47px;
  left: 0px;
}

.ext {
  color: transparent;
}

.batch-card {
  border: 1px solid #77c48c;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 85%;
  box-shadow: 0px 13.56650161743164px 37.68472671508789px 0px #3d78511a;
}

.batch-card p:first-child {
  font-weight: 700;
  font-size: 51px;
  color: #040404;
  margin-bottom: 0px;
}

.batch-card p:nth-child(2) {
  font-weight: 700;
}

.batch-card button {
  background: linear-gradient(101.6deg, #3d7851 4.72%, #3d7851 93.31%);
  color: #fff;
  height: auto;
  font-size: 32px;
  border: none;
  border-radius: 15px;
  width: 100%;
  padding: 15px 0px;
}

.batch-card button:hover {
  color: #000000;
  height: auto;
  font-size: 32px;
  border: none;
  border-radius: 15px;
  width: 100%;
  padding: 15px 0px;
}

.batch-card a {
  width: 100%;
}

.img-k2 {
  width: 93%;
}

.girl-img {
  position: relative;
}

.g-img1 {
  position: absolute;
  left: 0;
  top: 136px;
}

.g-img2 {
  position: absolute;
  right: 36px;
  top: 56px;
}

.g-img3 {
  position: absolute;
  right: 0;
  top: 280px;
}

.g-img4 {
  position: absolute;
  bottom: 110px;
  left: 6px;
}

.p-img1 {
  position: absolute;
  right: 42px;
  top: 60px;
}

.p-img2 {
  position: absolute;
  right: -47px;
  bottom: 60px;
}

.p-img3 {
  position: absolute;
  left: -6px;
  top: 226px;
}

.p-img4 {
  position: absolute;
  right: -50px;
  top: 243px;
}

.p-img5 {
  position: absolute;
  bottom: 60px;
  left: -42px;
}

.p-img6 {
  position: absolute;
  left: 74px;
  top: 50px;
}

.Girl2 {
  /* height: 530px; */
  height: auto;
}

.plat,
.tech {
  background-color: #edfcf1;
}

.mount {
  position: relative;
}

.mount-white {
  position: absolute;
  width: 100%;
  height: 100px;
  bottom: -46px;
  background-color: white;
  border-top-left-radius: 100%;
  border-top-right-radius: 100%;
}

.l-img1 {
  position: absolute;
  right: 86px;
  top: 102px;
}

.l-img2 {
  position: absolute;
  right: 18px;
  bottom: 39px;
}

.l-img3 {
  position: absolute;
  left: 29px;
  top: 258px;
}

.l-img4 {
  position: absolute;
  right: 0px;
  top: 242px;
}

.l-img5 {
  position: absolute;
  bottom: 32px;
  left: 33px;
}

.l-img6 {
  position: absolute;
  left: 95px;
  top: 81px;
}

.back {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tech,
.develop,
.corporation,
.university,
.training,
.about1 {
  background-color: #edfcf1;
  border-bottom-left-radius: 62% 13%;
  border-bottom-right-radius: 62% 13%;
  overflow: hidden;
}

.t-img1 {
  position: absolute;
  left: 73px;
  top: 115px;
}

.t-img2 {
  position: absolute;
  right: -8px;
  bottom: 113px;
}

.t-img3 {
  position: absolute;
  left: 29px;
  top: 360px;
}

.t-img4 {
  position: absolute;
  right: 30px;
  top: 86px;
}

.c-img1 {
  position: absolute;
  left: -8%;
  top: 19%;
}

.c-img2 {
  position: absolute;
  right: -67px;
  bottom: 161px;
}

.c-img3 {
  position: absolute;
  left: -8rem;
  top: 25rem;
}

.u-img1 {
  position: absolute;
  left: 4px;
  top: 115px;
}

.u-img2 {
  position: absolute;
  right: -71px;
  bottom: 228px;
}

.u-img3 {
  position: absolute;
  left: -117px;
  bottom: 0px;
}

.k-img1 {
  position: absolute;
  left: 4px;
  top: 115px;
}

.k-img2 {
  position: absolute;
  right: -71px;
  bottom: 228px;
}

.k-img3 {
  position: absolute;
  left: -117px;
  bottom: 0px;
}

@keyframes blink {
  0% {
    opacity: 1;
    transform: scale(1.1);
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.fa-circle-play {
  font-size: 60px;
  animation: blink 1.5s infinite;
  position: absolute;
  top: 38%;
  left: 46%;
  cursor: pointer;
  color: #3d7851;
  background-color: white;
  border-radius: 50%;
}

.about .logo5 {
  width: 100%;
}

.video2 .logo6 {
  width: 58vw;
}

.g-dot {
  width: fit-content;
  position: absolute;
  bottom: -26%;
  right: 0%;
  z-index: -1;
}

.cloud {
  margin: 50px 0px;
}

.bot .btn {
  width: auto;
  height: auto;
}

.social-sml a {
  color: transparent;
}

.founderimg {
  width: 100%;
}

.add-comment {
  cursor: pointer;
}

.dropdown:hover>.dropdown-menu {
  display: block;
}

.modal-content {
  display: flex;
  align-items: center;
  border-radius: 20px;
}

.th-cnt {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 30px;
}

.tnk-head {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 20px;
  border-radius: 16px 16px 0px 0px;
}

.tnk-head>img {
  width: 40%;
}

.tnk-btn {
  border: none;
  width: auto;
  margin: 20px;
  padding: 10px 25px;
  height: auto;
  background: #3d7851 !important;
}

.th-cnt>p {
  width: 100%;
  text-align: center;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  display: flex;
  align-items: center;
  border-radius: 20px;
}

.social-icon-sml {
  width: 35px;
  height: 35px;
  margin: 0px 10px;
  border-radius: 50%;
}

.user-comment img {
  width: 6vw;
  height: auto;
  margin-right: 16px;
}

.user-comment p:first-child {
  margin-bottom: 0;
}

.user-comment p:nth-child(2) {
  color: #b6b3b3;
}

.prev-nav,
.next-nav {
  color: #000;
  font-weight: bold;
}

.prev-nav:hover,
.next-nav:hover {
  color: #3d7851;
}

.custom-button {
  display: inline-block;
  padding: 8px 16px;
  margin: 4px;
  cursor: pointer;
  background-color: #007bff;
  /* Blue color, you can change this */
  color: #fff;
  /* White text color, you can change this */
  border: 1px solid #007bff;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.faq-body ul {
  list-style-type: disc;
  margin: 0 40px;
}

.faq-body p {
  margin-top: 1rem;
  margin-bottom: 0;
}

.author a,
.blog-date p {
  color: #77c48c;
  text-transform: capitalize;
}

.author a:hover,
.specificblog a:hover {
  /* text-decoration: underline; */
  color: #3d7851;
}

.blog-date span {
  color: #77c48c;
}

.navbar-logo {
  height: 57px;
  width: auto;
}

/* Toggle Button style */

.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #4ca363;
}

input:focus+.slider {
  box-shadow: 0 0 1px #4ca363;
}

input:checked+.slider:before {
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.btn-dyc {
  color: #fff !important;
  font-weight: 700;
  height: auto;
  padding: 5px 10px;
}

.pointer {
  cursor: pointer;
}

.w45 {
  width: 45%;
}

.until-next {
  background-color: #77c48c;
  color: #fff;
  text-transform: uppercase;
}

.w-90 {
  width: 90%;
}

.order-input {
  width: 50%;
}

.length-err {
  display: flex;
  align-items: center;
  margin-top: 0;
}

.len-err-img {
  font-size: 12px;
  margin-right: 6px;
}

.video2>iframe {
  position: absolute;
  top: 8.7%;
  left: 13.5%;
}

.about-lap>iframe {
  position: absolute;
  top: 1rem;
  left: 5.4rem;
  width: 27.9rem;
  height: 18rem;
  overflow: hidden;
}

.max {
  color: #ff8800;
  font-size: 12px;
}

.square-container {
  width: 100px;
  height: 100px;
  overflow: hidden;
  position: relative;
}

.square-container img {
  position: absolute;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.testimonial-bc-img div {
  background-color: #edfcf1;
  width: 40%;
  height: 30rem;
}

.testimonial-bc-img img {
  width: 60%;
  height: 30rem;
}

.testimonial-bc-img {
  display: flex;
}

.testimonial-content {
  top: 0;
  width: 100% !important;
  position: relative;
  background-color: #edfcf1;
}

.trainer .owl-theme .owl-nav {
  position: absolute;
  left: -35%;
  top: 40%;
}

.dummy-bg {
  position: absolute;
  top: 0;
  right: 0;
  height: 22rem;
  width: 48%;
  object-fit: cover;
}

.dummy-bg1 {
  position: absolute;
  top: 0;
  right: 0;
  height: 22rem;
  width: 48%;
  background-color: gray;
  object-fit: cover;
  opacity: 0.5;
}

.dummy-bg {
  position: absolute;
  top: 0;
  right: 0;
  height: 22rem;
  width: 48%;
  object-fit: cover;
}

.dummy-bg1 {
  position: absolute;
  top: 0;
  right: 0;
  height: 22rem;
  width: 48%;
  background-color: gray;
  object-fit: cover;
  opacity: 0.5;
}

.about-img-fouder {
  position: relative;
}

.overlay {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  /* Black see-through */
  width: 100%;
  transition: 0.5s ease;
  opacity: 1;
  padding: 20px;
}

.about-img-fouder:hover .overlay {
  opacity: 1;
}

.nav-link:focus,
.nav-link:hover {
  color: #77c48c;
}

.admin-box-container {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 16px;
  margin-bottom: 40px;
}

.admin-box-heading {
  background-color: #4ca363;
  padding: 10px;
  color: #fff;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.decision-image {
  width: 100%;
  margin: auto;
}

.radio-input {
  display: flex;
}

input[type="radio"] {
  accent-color: #3d7851;
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.wcardsec-2 {
  background-color: #edfcf1;
  padding: 14px;
  border-radius: 11px;
  margin-right: 15px;
}

.total-price {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 20px;
}

.total-green-bg {
  background-color: #edfcf1;
  background: linear-gradient(90.92deg, #3d7851 0%, #3d7851 100%);
  color: white;
}

.total-price .h5 {
  font-weight: 700;
}

.total-price tr td:first-child {
  width: 60%;
}

table {
  border-collapse: separate;
  border-spacing: 0 0.7em;
}

.total-gbg {
  color: white;
}

.checkout-btn {
  background: linear-gradient(90.92deg, #3d7851 0%, #3d7851 100%);
  width: 100%;
  border: none;
  padding: 10px;
  border-radius: 8px;
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.coupen-input input {
  width: 80%;
  height: 45px;
  border: none;
  border-right: 3px solid white;
  border-radius: 7px;
  padding: 5px 12px;
  font-weight: bold;
}

.coupen-input button {
  width: 20%;
  width: 20%;
  height: 45px;
  border: none;
  border-radius: 7px;
}

.coupen-input input[type="text"] {
  background-color: #ededed;
}

.coupen-input input:focus {
  outline: none;
}

.pricing-form label {
  font-weight: bold;
  height: 3.5rem;
  display: flex;
  align-items: center;
}

.cohort-list {
  height: 28rem;
  overflow-y: scroll;
}

/* width */
.cohort-list::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.cohort-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.cohort-list::-webkit-scrollbar-thumb {
  background: #3d7851;
  border-radius: 10px;
}

/* Handle on hover */
.cohort-list::-webkit-scrollbar-thumb:hover {
  background: #3d7851;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.pricing-form input {
  width: 7.8rem;
  height: 3rem;
  padding: 5px;
  font-weight: bold;
  font-size: 1.3rem;
  color: dimgray;
}

.pricing-form div {
  margin-right: 40px;
}

.pricing-form .material-symbols {
  font-size: 32px;
  position: relative;
  font-weight: bold;
  bottom: -2rem;
  background: linear-gradient(112.3deg, #3d7851 32.27%, #3d7851 67.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.pricing-form .material-symbols1 {
  font-size: 40px;
  position: relative;
  font-weight: bold;
  bottom: -1rem;
  background: linear-gradient(112.3deg, #3d7851 32.27%, #3d7851 67.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.cohert {
  background: linear-gradient(112.3deg, #3d7851 32.27%, #3d7851 67.07%);
  color: white;
  position: relative;
  left: -19px;
  padding: 6px 15px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}

.cohert-btn {
  border: 1px solid #77c48c;
  padding: 5px 20px;
  color: #77c48c;
  font-weight: bold;
  background: white;
  border-radius: 6px;
}

.radiolable {
  font-size: 1.3rem;
  font-weight: bold;
  display: flex;
  align-items: baseline;
}

.radio-on {
  color: #3d7851;
}

.total-green-bg td:nth-child(2) {
  color: white;
}

.feature-card-heading-admin {
  color: #007bff;
  font-weight: 700;
}

.feature-card-bottom {
  border-bottom: 1px solid #bdbcbc;
  margin-bottom: 30px;
}

.btn-none {
  background: none !important;
  border: none;
}

.btn-none:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%);
}

.backArrowContent span {
  color: #000;
}

.backArrowContent:hover span {
  color: #037024;
}

.dashboard-card {
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
  border-radius: 3px;
  background-color: #edfcf1;
  padding: 20px;
}

.Pending-card {
  box-shadow: rgba(230, 230, 74, 0.06) 0px 2px 4px 0px inset;
  border-radius: 8px;
  background-color: #d8e125e9;
  padding: 20px;
}

.contact-us-pricing {
  background: linear-gradient(97.33deg, #3d7851 0%, #3d7851 101.2%);
  color: white;
}

.contact-price-content {
  display: flex;
  flex-direction: column;
}

.coupenError {
  color: rgb(250, 10, 10);
  font-size: 0.8rem;
}

.char-hint {
  font-size: 12px;
}

.pricingdescription>p {
  margin-bottom: 0px;
  border: 1px solid lightgray;
  padding: 18px;
  border-radius: 13px;
  color: gray;
  width: 60%;
  font-size: 14px;
  color: #040404;
}

.pricingdescription span {
  color: #213626;
  font-weight: bold;
}

.content>.row>.col-lg-12 {
  z-index: 999;
  position: fixed;
  height: 15%;
  background: white;
  top: 88px;
  width: 74%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  border-radius: 11px;
}

.content>.row>.col-lg-12>div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonmodel2 {
  width: auto !important;
}

.divider {
  border-bottom: 2px solid lightgray;
  margin-bottom: 40px;
}

.alt-btn {
  background-color: #4ca363;
  color: white;
  border-radius: 8px;
  padding: 8px 20px;
  font-weight: 500;
  cursor: pointer;
}

.alt-btn:hover {
  background-color: white;
  color: #4ca363;
  border: 1px solid #213626;
}

/* .sidebarnavactive + .collapse:not(.show) {
  display: block;
  position: absolute;
  top: 55px;
  width: 90%;
}
.sidebarnavactive + .collapsing {
  display: block;
  position: absolute;
  top: 55px;
  width: 90%;
}
.sidebarnavactive + .collapse {
  display: block;
  position: absolute;
  top: 55px;
  width: 90%;
}

.sidebarnavactive {
  position: absolute;
  top: 0;
  width: 90%;
}
.sidebar {
  padding: 50px 15px 20px !important; 
}
.sidebarnavactive + .collapse .card-body {
  padding: 3rem 1rem 0rem !important;
} */

.template>div>p:first-child {
  font-weight: bold;
}

.trade-con .row>.card>div:first-child {
  height: 17rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.aboutusimg6 {
  display: flex;
  justify-content: center;
}

.validationWarn2 {
  color: #3D7851;
}

.lab .col-md-3 {
  display: flex;
  flex-direction: column;
  align-items: center;
}