ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.icon-container {
  cursor: pointer;
}

.icon-container:hover i {
  color: green;
  /* Change the color on hover */
}

.loginbg {
  height: 100vh;
  width: 100%;
  background-size: cover;
  display: flex;
  align-items: center;
  background-color: #ffffff;
}

.loginmodel {
  background: #f7f9f8;
  padding: 50px;
  width: 40vw;
  border-radius: 0px 20px 20px 0px;
}

.centertext {
  text-align: center;
}

.adminlogintitle {
  font-size: 22px;
  color: #4ca363;
  font-family: Nunito;
  font-weight: 700;
}

.logininput-field {
  width: 100%;
  padding: 10px 10px 10px 42px;
  border: 1px solid #4ca363;
  border-radius: 6px;
  color: #000000;
  outline: none;
  font-size: 16px;
  font-weight: 500;
}

.logininput-field::placeholder {
  color: #000000;
  font-weight: 500;
}

.loginicon {
  width: 3%;
  position: absolute;
  color: #000000;
  font-size: 22px;
  padding: 12px 12px;
}

.inputspace {
  margin-bottom: 30px;
  padding: 0px 30px;
}

.psw-icon {
  position: relative;
  bottom: 36px;
  right: 15px;
  cursor: pointer;
  float: right;
}

.psw-iconreset {
  position: relative;
  bottom: 36px;
  right: 15px;
  cursor: pointer;
  float: right;
  color: #000;
}

.psw-iconinput {
  position: absolute;
  bottom: 20%;
  right: 2%;
  cursor: pointer;
  float: right;
}

.loginbtn {
  border: none;
  padding: 8px;
  width: 100%;
  font-size: 16px;
  border-radius: 6px;
  font-weight: bold;
  background: #4ca363;
  color: #ffffff;
  border: 1px solid #4ca363;
}

.buttonmodel {
  border: none;
  padding: 10px 20px;
  width: 100%;
  font-size: 16px;
  border-radius: 10px;
  font-weight: 500;
  background: linear-gradient(101.6deg, #3D7851 4.72%, #3D7851 93.31%);
  color: #ffffff;
  cursor: pointer;
}

.loginbtn:hover {
  color: #4ca363;
  border: 1px solid #4ca363;
  background: transparent;
}

.forgettext {
  color: #000000;
  cursor: pointer;
}

.forgettext:hover {
  color: #4ca363;
}

.adminbackground {
  background: #edfcf1;
  height: 100vh;
}

.adminheader {
  padding: 15px 15px !important;
}

.messageimageletternavbar {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: #4ca363;
  display: grid;
  place-items: center;
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
}

.userprofilenavbar {
  background: #fff;
  border-radius: 50%;
  padding: 2px;
  height: 40px;
  width: 40px;
}

.headerfloat {
  float: right;
}

.sidebar {
  padding: 20px 15px;
  height: 85%;
  width: 20%;
  position: absolute;
  overflow-x: auto;
  margin-top: 6rem;
}

.sidebar::-webkit-scrollbar {
  display: none;
}

.content,
.contentdashboard {
  position: absolute;
  width: 78%;
  left: 20%;
  padding: 20px;
  height: 85%;
  overflow-x: auto;
  background-color: white;
  border-radius: 20px;
}


.content::-webkit-scrollbar,
.contentdashboard::-webkit-scrollbar {
  display: none;
}

.marginspace {
  margin-top: 0px;
  margin-bottom: 10px;
}

.sidebartitle {
  font-size: 15px !important;
  color: #000;
}

.sidebaricon {
  font-size: 23px !important;
  color: #000;
}

.displayflex {
  display: flex;
}

.sidebartitle:hover,
.sidebaricon:hover {
  color: #4ca363;
}

.sidebarnavactive {
  padding: 8px;
  background-color: #4ca363;
  border-radius: 6px;
}

.sidebarnavinactive {
  padding: 8px;
}

.sidebarnavactive .sidebartitle,
.sidebarnavactive .sidebaricon {
  color: #ffffff;
}

.sidebarnavinactive:hover>.displayflex .sidebaricon,
.sidebarnavinactive:hover>.displayflex .sidebartitle {
  color: #4ca363;
}

.sidebarnavactive .material-symbols-outlined {
  color: #fff;
}

.marginspace .card-body {
  padding: 0rem 1rem;
}

.contenttitle {
  font-size: 25px;
  color: #4ca363;
}

.css-pdct74-MuiTablePagination-selectLabel {
  margin-bottom: 0 !important;
}

.css-levciy-MuiTablePagination-displayedRows {
  margin-bottom: 0 !important;
}

.arrowicon {
  color: #4ca363;
  font-size: 30px;
  font-weight: 900;
  cursor: pointer;
}

.validationerror {
  color: red;
  font-size: 14px;
  margin-top: 8px;
  margin-left: 5px;
}

.validationWarn {
  color: rgb(218, 197, 38);
  font-size: 14px;
  margin-top: 8px;
  margin-left: 5px;
}

/* Image Design */
.progress-profile {
  width: 60px;
  height: 60px;
  font-size: 20px;
  line-height: 57px;
}

.progress-profile .title {
  position: relative;
  z-index: 100;
  height: 60px;
}

.PP-upload-img {
  width: 50px;
  height: 50px;
}

.photo-icon-css2 {
  padding: 2px;
  z-index: 300;
}

.profilesection1-content .profile-name2 {
  width: 60px;
  height: 60px;
  border: 5px solid #4ca363;
}

.addpic {
  color: #4ca363;
  font-size: 25px;
  margin-top: -45px;
  margin-left: 10px;
  cursor: pointer;
}

.removepic {
  color: red;
  font-size: 25px;
  margin-top: -45px;
  margin-left: 5px;
  cursor: pointer;
}

.messageimagelettercircle {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background: #ffffff;
  display: grid;
  place-items: center;
  font-size: 25px;
  font-weight: 500;
  color: #4ca363;
  margin: auto;
}

.labeltext {
  font-size: 16px;
  font-weight: 500;
  color: #252c58;
  margin-top: 2%;
}

.fieldinput {
  width: 100%;
  outline: none;
  border-radius: 8px;
  padding: 7px 15px;
  border: 1px solid #9195ab;
  margin-top: 5px;
}

.fieldinputemail {
  width: 100%;
  outline: none;
  border-radius: 8px;
  padding: 7px 15px;
  border: 1px solid #9195ab;
  margin-top: 5px;
}

.fieldinputfilereport {
  width: 45%;
  padding: 5px;
  border: none;
  margin-top: 5px;
}

.contentbox {
  padding: 20px 30px;
  border-radius: 10px;
  background: #ffffff;
  margin-bottom: 3%;
  margin-top: 6%;
}

.aligncenterone {
  display: flex;
  justify-content: center;
}

.otpspace {
  margin-right: 20px !important;
}

.displayflexspace {
  display: flex;
  justify-content: space-between;
}

.notfoundimg {
  width: 75%;
}

.rdw-editor-main {
  height: 250px !important;
  overflow: auto;
  box-sizing: border-box;
}

.rdw-editor-main::-webkit-scrollbar {
  display: none;
}

.bottomListItem {
  position: absolute;
  bottom: 0;
  margin-bottom: 40px;
  cursor: pointer;
}

.tss-1qtl85h-MUIDataTableBodyCell-root {
  padding: 0px 15px;
}

.namelink {
  text-transform: capitalize;
  font-size: 16px;
  cursor: pointer;
  margin-left: 15px;
  padding: 8px 10px;
  border: 1px solid #4ca363;
  border-radius: 8px;
}

.namelink:hover {
  background-color: #4ca363;
  color: white;
}

.textformat {
  text-transform: capitalize;
}

.searchtopflex {
  display: flex;
  justify-content: space-evenly;
  cursor: pointer;
}

.agenttextactive {
  font-weight: 700;
  font-size: 16px;
  line-height: 37px;
  text-align: center;
  text-transform: capitalize;
  color: #4ca363;
  border-bottom: 3px solid #4ca363;
  margin-left: 5px;
}

.agenttextinactive {
  margin-top: 1%;
  font-size: 16px;
  color: #ed8893;
  font-weight: 700;
  line-height: 34px;
  margin-left: 5px;
}

.headerfloat .dropdown-menu {
  inset: 0px auto auto 0px;
  transform: translate(-120px, 42px);
}

.material-symbols-outlined {
  cursor: pointer;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20000;
  /* overflow-x: hidden; */
  padding-top: 0%;
  /* overflow-y: auto; */
}

.Modal-View {
  width: 40%;
}

.delete-modal {
  background-color: white;
  height: 42vh;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.btn-cancel {
  border: 2px solid #4ca363;
  padding: 10px 30px;
  border-radius: 6px;
  background-color: #fff;
  color: #4ca363;
}

.delete-modal .fa-circle-xmark {
  font-size: 32px;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 5px;
}

.delete-modal .material-symbols-outlined:hover {
  cursor: pointer;
}

.css-1mw4qtd-control,
.css-yo9ie1-control {
  width: 90%;
  border-radius: 8px !important;
  padding: 0px 15px !important;
  border: 1px solid #9195ab;
  margin-top: 5px;
  height: 40px !important;
}

.css-8aqfg3-menu {
  width: 80%;
}

.userprofilecircle {
  width: 100%;
  height: auto;
  border-radius: 50%;
}

.batchselect {
  color: gainsboro;
}

.bx-box {
  border: 1px solid #3d7851;
  margin: 10px 0px !important;
  padding: 10px;
  border-radius: 10px;
  align-items: center;
}

.bx-box .labeltext {
  margin: 0;
}

.css-11mde6h-MuiPaper-root,
.css-ee20y-MuiPaper-root {
  border-radius: 20px !important;
  margin: 40px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
}

.login-left {
  background-color: #96ecad;
  width: 40vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0px;
  border-radius: 20px 0px 0px 20px;
  justify-content: center;
}

.forgot-pass-layout {
  border-radius: 20px;
  background-color: #d5fde0;
}

.pass-input {
  position: relative;
}

.textarea {
  white-space: pre-line;
}

.top-heading-admin {
  position: fixed;
  top: 15%;
  width: 73%;
  justify-content: space-between;
  /* justify-content: center; */
  align-items: center;
  display: flex;
  background: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
}

.back-view {
  width: fit-content;
  height: 100%;
  background-color: #4ca363;
  align-items: center;
  display: flex;
  padding: 16px 18px;
  border-radius: 8px;
  color: white;
  position: relative;
}

.cancel {
  color: #fff;
}

.close-x-btn {
  position: absolute;
  top: 0px;
  color: white;
  right: 0px;
  font-size: 24px;
}